<template>
  <div class="footer_wrap">
    <!-- <div class="footer_top_bg"></div> -->
    <div class="footer_content">
      <div class="footer_top">
        <div class="footer_top_left">
          <div class="footer_top_subTitle">
            <h3>联系我们</h3>
            <span>CONTACT US</span>
          </div>

          <div class="footer_top_info_item">
            <img :src="footerAdress" />
            地址：文安县月星生活广场15层
          </div>

          <div class="footer_top_info_item">
            <img :src="footerEmail" />
            邮编：226100
          </div>

          <div class="footer_top_info_item">
            <img :src="footerPhone" />
            电话：0316-5286808
          </div>
          <div class="footer_top_info_item">
            <img :src="footerPhone" />
            派驻纪检监察组电话：0513-69909722
          </div>
        </div>
        <!-- 
        <div class="footer_top_right">
          <div class="footer_form_wrap">
            <el-form label-position="top" label-width="50px" v-model="formData">
              <div class="footer_form_top">
                <el-form-item label="您的名字">
                  <el-input v-model="formData.userName"></el-input>
                </el-form-item>
                <el-form-item label="您的电话">
                  <el-input v-model="formData.userPhone"></el-input>
                </el-form-item>
              </div>

              <div class="footer_form_center">
                <el-form-item label="您的留言">
                  <el-input type="textarea" v-model="formData.content" />
                </el-form-item>
              </div>

              <div class="footer_form_bottom">
                <el-form-item label="验证码">
                  <el-input v-model="formData.code" />
                  <img
                    :src="codeImg"
                    style="cursor: pointer"
                    @click="reLoadImg"
                  />
                </el-form-item>
              </div>
              <div
                class="footer_form_btn"
                @click="postUserInfo"
                style="margin-bottom: 15px; color: #009fff"
              >
                提交
              </div>
              <div class="footer_form_btn" @click="clearFormData">重填</div>
            </el-form>
          </div>
        </div> -->
      </div>
      <div class="footer_bottom">
        <div class="footer_bottom_li_wrap">
          <p v-for="item in footerList" :key="item.title">
            {{ item.title }}
          </p>
        </div>
        <p>
          COPYRIGHT ©2022-现在 文安县城发集团
          <span @click="toHref" style="cursor: pointer"
            >苏ICP备2022032945号</span
          >
        </p>
      </div>
    </div>
  </div>
</template>


<script>
import imgData from "@/data.js";
import { reactive, computed, watchEffect, ref } from "vue";
import { submitUserInfo } from "@/api/index";
import { mainStore, stateStore } from "@/store";
import { storeToRefs } from "pinia";
import { ElMessage } from "element-plus";
export default {
  setup() {
    const store = mainStore();
    const footerList = [
      {
        title: "注册声明",
      },
      {
        title: "版权声明",
      },
      {
        title: "售后服务",
      },
      {
        title: "隐私声明",
      },
    ];
    const reLoadImg = store.setactiveImgCodeInfo;
    const postUserInfo = () => {
      if (!data.formData.code) {
        ElMessage({
          type: "error",
          message: "请输入验证码",
        });
        return;
      }
      if (
        storeToRefs(store).activeImgCode.value.toLowerCase() ==
        data.formData.code.toLowerCase()
      ) {
        submitUserInfo(data.formData)
          .then((res) => {
            ElMessage({
              type: "success",
              message: "提交成功",
            });
            clearFormData();
            reLoadImg();
          })
          .catch((err) => {
            ElMessage({
              type: "error",
              message: "提交失败，请检查格式是否正确",
            });
          });
      } else {
        ElMessage({
          type: "error",
          message: "请输入正确的验证码",
        });
      }
    };
    const codeImg = computed(() => {
      return storeToRefs(store).activeImg.value;
    });
    const codeKey = computed(() => {
      return storeToRefs(store).activeImgKey.value;
    });
    const clearFormData = () => {
      data.formData.userName = "";
      data.formData.userPhone = "";
      data.formData.content = "";
      data.formData.code = "";
    };
    const toHref = () => {
      window.open("https://beian.miit.gov.cn/", "_blank");
    };
    const data = reactive({
      formData: {},
      footerList,
      postUserInfo,
      clearFormData,
      toHref,
    });

    return {
      ...data,
      ...imgData,
      codeImg,
      codeKey,
      reLoadImg,
    };
  },
};
</script>

<style >
@import url("./Footer.css");
</style>